html,
body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swivel-items {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.swivel-item {
  flex: 1;
  aspect-ratio: 1;
  margin: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  /* border-bottom: 1px solid #aaa; */
  margin: 0 0;
  padding: 0;
  display: flex;
}

.react-tabs__tab {
  background: rgb(116, 219, 115);
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  /* bottom: -1px; */
  position: relative;
  list-style: none;
  padding: 15px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
}

.react-tabs__tab--selected {
  background: rgb(245, 245, 245);
  /* border-color: #aaa; */
  color: black;
  /* border-radius: 5px 5px 0 0; */
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  /* height: 5px; */
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
  background-color: rgb(245, 245, 245);
  overflow: auto;
  /* padding: 5px */
}

.react-tabs__tab-panel--selected {
  display: block;
  height: 100%;
}

/***************/

.generic-background {
  background-color: rgb(245, 245, 245);
}

.shadow {
  -moz-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
}

.shadow-button {
  -moz-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  user-select: none;
}

.shadow-button:active {
  box-shadow: none;
  position: relative;
  top: 1px;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.input-gray-inner {
  width: 100%;
  border: none;
  background-color: rgb(245, 245, 245);
  height: 2em;
  box-shadow: inset 1px 1px 1px 1px rgba(176, 176, 176, 1);
  resize: vertical;
}

.input-white-inner {
  width: calc(100% - 2em);
  border: none;
  background-color: rgb(255, 255, 255);
  height: 2em;
  box-shadow: inset 1px 1px 1px 1px rgba(176, 176, 176, 1);
  resize: vertical;
  cursor: not-allowed;
}

.next-step:after {
  color: rgb(167, 167, 167);
  border-left: 13px solid;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  display: inline-block;
  content: "";
  position: absolute;
  right: -12px;
}

.next-step {
  background-color: rgb(167, 167, 167);
  /* width: 100px; */
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  position: relative;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.button {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  /* gap: 8px; */
  border-radius: 6px;
  border: 2px solid #fb24ff;
  cursor: pointer;
}

.button:hover {
  background-color: #fbdeff;
}

.button:active {
  position: relative;
  top: 1px;
}

.margin-right {
  margin-right: 0.5em;
}

.typography {
  color: #fb24ff;
  text-align: center;
  font-family: ABC Diatype Variable Unlicensed Trial;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 21px */
  letter-spacing: 0.15px;
}

.button-alt {
  cursor: pointer;
  margin-top: 1em;
  width: calc(100% - 4em);
  border: none;
  display: flex;
  height: 50px;
  padding: 2px 24px;
  border-radius: 16px;
  background: #8bfe62;
  box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.25);
}

.button-alt:active {
  position: relative;
  top: 1px;
  box-shadow: none;
}

.button-alt-typography {
  font-family: ABC Diatype Variable Unlicensed Trial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 19.6px */
  letter-spacing: 0.14px;
}

.smart-action-option {
  background-color: rgb(241, 241, 241);
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
  padding: 1em;
}

.set-by-smart-action {
  background: #cbffe3;
}

.set-by-smart-action-error {
  background: rgb(237, 107, 102);
}

.manual-override {
  background: rgb(232, 196, 68);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-color: rgb(245, 245, 245);
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 10px;
  border: none;
  /* border-radius: 2px; */
  /* margin-right: 2rem; */
  padding: 1em;
  padding-right: 2rem;
}

.button-custom-field {
  border: none;
  font-weight: bold;
  border-radius: 5px;
  padding: 0.75em;
  color: rgb(151, 151, 151);
  background-color: rgb(224, 224, 224);
  cursor: pointer;
}

.button-custom-field:hover {
  background-color: rgb(234, 234, 234);
}

.button-custom-field:active {
  position: relative;
  top: 1px;
}

.custom-field-ui {
  background-color: rgb(245, 245, 245);
  border: 2px solid rgb(217, 217, 217);
  margin-right: 1em;
  display: flex;
  flex-direction: column;
}

.custom-field-type-button {
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
  padding: 0.5em;
  font-weight: bold;
  font-size: 10px;
  cursor: pointer;
}

.custom-field-type-button:hover {
  background-color: rgb(245, 245, 245);
}

.custom-field-type-button:active {
  position: relative;
  top: 1px;
}

.custom-field-type-button-selected {
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
  padding: 0.5em;
  font-weight: bold;
  font-size: 10px;
  cursor: pointer;
  background-color: lightgray;
}

.file-upload-box {
  width: 100%;
  min-height: 5em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-upload-box-inner {
  font-family: ABC Diatype Semi-Mono Variable Edu;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 18.2px */
  letter-spacing: 0.26px;
  color: rgb(151, 151, 151);
  user-select: none;
  width: 50%;
  text-align: center;
}

.file-upload-file {
  font-family: ABC Diatype Semi-Mono Variable Edu;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 18.2px */
  letter-spacing: 0.26px;
  color: rgb(245, 245, 245);
  user-select: none;
  margin-top: 1em;
  max-height: 20px;
  background-color: silver;
  padding: 0.75em;
  border-radius: 3px;
  display: flex;
}

.file-upload-file:active {
  position: relative;
  top: 1px;
}

.file-upload-file-left {
  float: left;
  flex: 8;
  cursor: pointer;
}

.file-upload-file-right {
  float: right;
  flex: 2;
  cursor: pointer;
}

.file-upload-file-icon {
  padding-right: 5px;
  float: right;
  cursor: pointer;
}

.calendar-field {
  background-color: rgb(245, 245, 245);
  font-size: 0.75em;
  line-height: 140%;
  letter-spacing: 0.24px;
  padding: 1em;
}

.agreement-draft {
  margin: 1em;
}

.agreement-deployed {
  margin-left: 1em;
  margin-right: 1em;
}

.agreement-read-only {
  background-color: rgb(245, 245, 245);
  width: 100%;
  border: none;
  height: 2em;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  /* padding: 0.3em; */
  cursor: default;

  font-family: ABC Diatype Semi-Mono Variable Edu;
  font-size: 14px;
  font-style: normal;
  font-weight: 512;
  line-height: 140%;
}

.agreement-read-only-inner {
  padding: 0.3em;
}

.submit-invoice-button {
  display: flex;
  height: 10px;
  width: 200px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #42de64;
  color: white;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.submit-invoice-button:active {
  position: relative;
  top: 1px;
}

.agreement-table-status {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  padding: 0.35em;
  text-transform: uppercase;
  user-select: none;
  cursor: default;
}

.agreement-table-draft {
  color: red;
  border: 2px solid red;
}

.agreement-table-deployed {
  color: black;
  border: 2px solid black;
}

.invoice-status {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5em;
  padding: 0.5em;
  font-weight: bold;
  font-size: 25px;
  text-transform: uppercase;
  cursor: default;
  user-select: none;
}

.invoice-status-rejected {
  border: 3px solid red;
  color: red;
}

.invoice-status-draft {
  border: 3px solid gray;
  color: gray;
}

.invoice-status-approved {
  border: 3px solid green;
  color: green;
}

.invoice-status-pending {
  border: 3px solid orange;
  color: orange;
}

.palette-icon {
  width: 6em;
  height: 6em;
  cursor: pointer;
}

.palette-icon:hover {
  background-color: lightgreen;
}